<template>
	<div class="pageContainer">
		<web-header></web-header>
		<div class="content">
			<div class="main">
				<router-title :menuList="menuList[0]" :list="menuList[1]" :list1="menuList[2]" />
			</div>
			<div class=" main">
				<div class="contentOne">
					<p>课程清单</p>
					<div class="cardCls">
						<!-- 直播进入 -->
						<img v-if="this.courseType === '1'" :src="derailC.surfacePlot" alt="">
						<!-- 订单进入，为集体支付的时候 -->
						<img v-else-if="derailC.isCollective === 1 && this.courseType === '3'"
							src="../../assets/index/2.png" alt="">
						<img v-else :src="derailC.coverPicture" alt="">
						<div class="cardIn">
							<h3 v-if="derailC.isCollective === 1">集体报名</h3>
							<h3 v-else-if="this.courseType === '2' || this.courseType === '1'">{{derailC.name}}</h3>
							<h3 v-else-if="this.courseType === '3' || derailC.isCollective === 0">{{derailC.orderName}}
							</h3>
							<h4>
								<span>价格：</span>
								<span v-if="this.courseType === '2' || this.courseType === '1'">￥{{derailC.cost}}</span>
								<span v-else-if="this.courseType === '3'">￥{{derailC.unitPrice}}</span>
							</h4>
						</div>
					</div>
				</div>
				<div class="contentTwo">
					<p>支付方式</p>
					<div class="cardCls">
						<el-radio v-model="radio" :label="item.type" v-for="item in payMethod" :key="item.type">
							<div class="payCls">
								<img :src="item.icon" alt="">
								{{item.name}}
							</div>
						</el-radio>
					</div>
				</div>
			</div>
			<div class="contentFour">
				<div class="fourCard">
					<div>请在24小时完成支付，逾期订单失效。</div>
					<div>
						<div>总价：<span
								style="color: #39AEFF;margin-right: 35px;">￥{{derailC.cost}}{{derailC.unitPrice}}</span>
						</div>
						<!-- <el-button v-if=" this.courseType === '3' && derailC.isCollective === 0" @click="getJtiPay">提交订单</el-button> -->
						<el-button @click="sumbitEvt">提交订单</el-button>
						<!-- <el-button v-else-if="!derailC.isCollective" @click="">提交订单</el-button> -->
					</div>
				</div>
			</div>
		</div>
		<web-footer></web-footer>
	</div>
</template>

<script>
	import WebFooter from '../../components/webFooter.vue'
	import webHeader from '../../components/webHeader.vue'
	import RouterTitle from "../../components/routerTitle.vue";

	import {
		mapState
	} from 'vuex';
	export default {
		components: {
			webHeader,
			WebFooter,
			RouterTitle
		},
		data() {
			return {
				menuList: ["首页", "培训项目", "订单"],
				payMethod: [],
				radio: '',
				list: [{
						label: '培训费',
						value: 1
					},
					{
						label: '电子发票',
						value: 2
					},
				],
				// 课程详情
				derailC: {},
				// 判断是从哪个界面过来的
				courseType: '',
				// 课程id
				courseId: '',
				// 订单id
				ordeId: '',
				// 订单过来传的值
				orderidList: {},
				// 其他页面过来的值
				courseIdList: {}
			}
		},
		created() {
			this.derailC = this.$route.query.data
			this.courseType = this.$route.query.type

			if (this.courseType === '3') {
				if (this.$route.query.isCollective === '1') {
					this.courseId = localStorage.getItem('cousreId')
					this.getOrderList(this.courseId)
				} else if (this.$route.query.isCollective === '0') {
					this.courseId = localStorage.getItem('cousreId')
					this.getOrderList(this.courseId)
				}
			} else {
				if (this.courseType === '2') {
					this.courseId = localStorage.getItem('cousreId')
					this.gitCourelest(this.courseId)
				} else if (this.courseType === '1') {
					this.courseId = localStorage.getItem('cousreId')
					this.getliceList(this.courseId)
				}
			}

			this.payMethod = this.payByList.map(item => {
				let icon, size;
				switch (item.type) {
					case 1:
						icon = require('../../assets/index/wxzhifu.png');
						break;
					case 2:
						icon = require('../../assets/index/zhifubao.png');
						break;
					case 3:
						icon = require('../../assets/index/yinlian.png');
						break;
				}
				item.icon = icon;
				return item
			})
			this.radio = this.payByList[0].type;
		},
		computed: {
			...mapState(['payByList'])
		},
		methods: {
			// 订单过来的订单详细数据
			getOrderList(id) {
				console.log(id, 'id');
				this.$http.post('byOrderCode', {
					orderCode: id
				}).then(res => {
					if (res.code === 200) {

						this.derailC = res.data
						this.courseId = res.data.id
						this.orderidList = {
							courseType: 2,
							// id: 11,
							payType: this.radio,
							// PC端
							requestType: 1,
							orderId: res.data.id
						}
					} else {
						this.$message.error(res.message)
					}
				})
			},
			// 获取直播的详细信息
			getliceList(id) {
				console.log(this.radio);
				this.$http.post('liveById', {
					id
				}).then(res => {
					if (res.code === 200) {
						this.derailC = res.data
						this.courseId = res.data.id
						this.orderidList = {
							courseType: this.courseType,
							id: res.data.id,
							// id: 11,
							payType: this.radio,
							// PC端
							requestType: 1,
						}
					} else {
						this.$message.error(res.message)
					}
				})
			},
			// 获取课程详细信息
			gitCourelest(id) {
				this.$http.post('getCourseInfoById', {
					id
				}).then(res => {
					if (res.code === 200) {
						this.derailC = res.data
						this.courseId = res.data.id
						this.orderidList = {
							courseType: this.courseType,
							id: res.data.id,
							// id: 11,
							payType: this.radio,
							// PC端
							requestType: 1,
						}
					} else {
						this.$message.error(res.message)
					}
				})
			},
			radio1Change(e) {
				this.dialogVisible = true
			},
			// // 集体支付
			// getJtiPay(){
			//   console.log('集体支付');
			//    this.$http.post('getCollectiveQrCode', { id: this.courseId, type: this.radio - 0 , requestType:1}).then(res => {
			//       if (res.code === 200) {
			//         if(this.radio ==='2'){
			//              this.$message.success('正在跳转。。。')
			//             let divForm = document.getElementsByTagName('divform')
			//               if (divForm.length) {
			//                 document.body.removeChild(divForm[0])
			//               }
			//               const div = document.createElement('divform')
			//               div.innerHTML = res.data // res.data就是sb支付宝返回给你的form
			//               document.body.appendChild(div)
			//               document.forms[0].submit();

			//         }else {
			//             var arr = res.data.split(',');
			//           const data = {
			//             id: this.id,
			//             cost: this.price
			//           }
			//            this.$router.push({
			//             path: '/trainingProgram/orderSumbit',
			//             query: { url: arr,index: this.radio,data:data,jitiBM: 'y'}
			//           })

			//         }
			//       } else {
			//         this.$message.error(res.message)
			//       }
			//     })
			// },
			//提交
			sumbitEvt() {
				this.orderidList.payType = this.radio
				if (this.derailC.cost === 0 && this.courseType === 1) {

					if (this.$route.query.type === '1') {

						this.$router.push({
							path: '/liveInfo',
							query: {
								id: this.derailC.id
							}
						})
					} else if (this.$route.query.type === '2') {}
				} else {

					this.$http.post('payMoney', this.orderidList).then(res => {

						if (res.code === 200 && res.data) {
							let okorder = ''
							okorder = res.data.substring(0, res.data.indexOf(','))
							console.log(this.radio, 'radio');
							console.log(typeof this.radio);
							console.log(res.data.substring(0, res.data.indexOf(',')), '123');
							console.log(typeof res.data.substring(0, res.data.indexOf(',')));
							if ((okorder === '免费课程' && this.radio === 1) || (res.data === '免费课程' && this.radio ===
									2) || (okorder === '免费课程' && this.radio === 3)) {
								console.log(11111111);
								this.$router.push({
									path: '/trainingProgram/paymentResults',
									// query: {
									// 	data: this.derailC,
									// 	orderCode: res.data.substring(1,2)
									// }
								})

							} else if (this.radio === '2') {
								console.log(222222222);
								this.radio = '2'
								this.$message.success('正在跳转。。。')
								let divForm = document.getElementsByTagName('divform')
								if (divForm.length) {
									document.body.removeChild(divForm[0])
								}
								//  let routerData = this.$router.resolve({path:'/trainingProgram/applyPage',query:{htmls:res.data}})
								// //打开新页面
								window.open(routerData.href, '_ blank')
								const div = document.createElement('divform')
								div.innerHTML = res.data // res.data就是sb支付宝返回给你的form
								document.body.appendChild(div)
								document.forms[0].submit();
							} else {
								console.log(33333333);
								//  if(this.radio === '1'){
								var arr = res.data.split(',');
								this.$router.push({
									path: '/trainingProgram/orderSumbit',
									query: {
										url: arr,
										index: this.radio,
										data: this.derailC
									}
								})
							}
						} else {
							this.$message.error(res.message)
						}
					})
				}

			}
		}
	}
</script>

<style lang="less" scoped>
	.pageContainer {
		background: #eee;
	}

	.main {
		width: 1200px;
		margin: auto;
	}

	.contentOne {
		height: 223px;
		background: #FFFFFF;
		box-shadow: 0px 0px 13px 0px rgba(53, 53, 53, 0.13);
		padding-top: 20px;

		p {
			border-left: 3px solid #39AEFF;
			padding-left: 15px;
			font-size: 20px;
			font-family: PingFang SC;
			font-weight: bold;
			color: #333333;
		}

		.cardCls {
			display: flex;
			margin: 40px 40px 0 60px;

			img {
				width: 118px;
				height: 90px;
				display: block;
				margin-right: 40px;
			}

			.cardIn {
				h3 {
					font-size: 20px;
					font-family: PingFang SC;
					font-weight: 500;
					color: #333333;
					margin-bottom: 30px;
				}

				h4 {
					span {
						font-size: 16px;
						font-family: PingFang SC;
						font-weight: 500;
						color: #999999;
					}

					span:nth-child(2) {
						color: #39AEFF;
					}
				}
			}

		}
	}

	.contentTwo {
		width: 1200px;
		height: 148px;
		background: #FFFFFF;
		box-shadow: 0px 0px 13px 0px rgba(53, 53, 53, 0.13);
		margin: 25px 0;
		padding-top: 20px;

		p {
			border-left: 3px solid #39AEFF;
			padding-left: 15px;
			font-size: 20px;
			font-family: PingFang SC;
			font-weight: bold;
			color: #333333;
		}

		.cardCls {
			margin-left: 60px;
			margin-top: 25px;
			display: flex;

			/deep/.el-radio {
				display: flex;
			}

			/deep/.el-radio__inner {
				margin-top: 10px;
			}

			.payCls {
				width: 146px;
				height: 35px;
				border: 1px solid #EEEEEE;
				display: flex;
				line-height: 30px;

				img {
					width: 36px;
					height: 27px;
					display: block;
					margin-left: 20px;
					margin-right: 10px;
					margin-top: 3px;
				}
			}

			.payCls1 {
				width: 146px;
				height: 35px;
				border: 1px solid #EEEEEE;
				display: flex;
				line-height: 30px;
				padding-left: 20px;
			}
		}
	}

	.contentFour {
		margin: 0 auto;
		height: 147px;
		background: #FFFFFF;
		box-shadow: 0px 0px 13px 0px rgba(53, 53, 53, 0.13);
		margin-top: 50px;
		line-height: 147px;
		text-align: center;

		.fourCard {
			height: 147px;
			width: 1200px;
			margin: 0 auto;

			// display: flex;
			// position: relative;
			div:nth-child(1) {
				float: left;
				font-size: 18px;
				font-family: PingFang SC;
				font-weight: bold;
				color: #333333;
				// margin-left: 330px;
			}

			div:nth-child(2) {
				float: right;
				font-size: 18px;
				font-family: PingFang SC;
				font-weight: bold;
				color: #333333;
				margin-right: 20px;

				.el-button {
					width: 112px;
					height: 41px;
					background: linear-gradient(163deg, #39AEFF, #F45849);
					line-height: 41px;
					text-align: center;
					color: #fff;
					font-size: 14px;
					cursor: pointer;
					line-height: 19px;
				}
			}

			// div:nth-child(3){
			//   width: 112px;
			//   height: 41px;
			//   background: linear-gradient(163deg, #39AEFF, #F45849);
			//   line-height: 41px;
			//   text-align: center;
			//   color: #fff;
			//   font-size: 14px;
			//   margin-top: 55px;
			//   margin-left: 30px;
			//   cursor: pointer;
			// }
		}
	}

	/deep/.el-dialog__body {
		padding: 20px 80px;

		.cardCls {
			display: flex;

			/deep/.el-radio {
				display: flex;
			}

			/deep/.el-radio__inner {
				margin-top: 10px;
			}

			.payCls {
				width: 146px;
				height: 35px;
				border: 1px solid #EEEEEE;
				display: flex;
				line-height: 30px;

				img {
					width: 36px;
					height: 27px;
					display: block;
					margin-left: 20px;
					margin-right: 10px;
					margin-top: 3px;
				}
			}

			.payCls1 {
				width: 146px;
				height: 35px;
				border: 1px solid #EEEEEE;
				display: flex;
				line-height: 30px;
				padding-left: 20px;
			}
		}
	}

	.dialog-footer {
		text-align: center;

		/deep/.el-button {
			display: block;
			margin: 0 auto;
			width: 107px;
			background: #39AEFF;
			font-size: 14px;
			font-family: PingFang SC;
			font-weight: 500;
			color: #FFFFFF;
		}

		p {
			font-size: 12px;
			font-family: PingFang SC;
			font-weight: 400;
			color: #39AEFF;
			margin-top: 20px;
		}
	}

	/deep/.el-radio__input.is-checked .el-radio__inner {
		border-color: #39AEFF;
		background: #39AEFF;
	}

	/deep/.el-radio__input.is-checked+.el-radio__label {
		color: #333333;
	}

	.twoTop {
		font-size: 14px;
		color: #666;
	}
</style>
